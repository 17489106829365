import React from 'react';

const Introduction: React.FC<{
  title: string;
  children: React.ReactNode;
  align?: 'center' | 'left';
  extraElement?: React.ReactNode;
}> = ({ title, children, align, extraElement }) => {
  return (
    <div className="relative flex flex-col pc:flex-row justify-center items-center min-h-[192px] pc:bg-[url('/public/images/default_bg.png')] bg-cover bg-[url('/public/images/default_bg_sp.png')]">
      <div
        className={`relative flex flex-col ${
          align === 'left' ? 'items-center pc:items-start' : 'items-center'
        } m-[20px] z-20 max-w-[768px] pc:max-w-none`}
      >
        <h1 className='font-serif text-3xl pc:text-4xl font-bold text-center antialiased'>
          {title}
        </h1>
        <div
          className={`text-[#333333] text-[13px] mid:text-[17px] ${
            align === 'left' ? 'text-center pc:text-left' : 'text-center'
          } font-bold font-serif leading-loose mt-[10px] antialiased`}
        >
          {children}
        </div>
      </div>
      {extraElement}
      <img
        className='absolute w-[100px] pc:w-[150px] object-contain top-[10px] z-10'
        src='/images/lotos_left.png'
        alt='蓮華の画像'
      />
      <img
        className='absolute w-[100px] pc:w-[150px] object-contain bottom-[10px]  z-10'
        src='/images/lotos_right.png'
        alt='蓮華の画像'
      />
    </div>
  );
};

export default Introduction;
