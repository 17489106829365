import { EventType } from '../components/Events/EventBox';

export const eventList = [
  {
    pageId: 0,
    title: 'JA兵庫西JAやすらぎホール佐用秋のふれあいフェスタ',
    metaTitle:
      '【参加無料】秋のふれあいフェスタ 餅まきや出店などお子様と一緒に楽しめます',
    eventDate: '2024年10月14日(月)9:00~13:00',
    location: {
      name: 'JAやすらぎホール佐用',
      companyUrl: 'https://harada-kyodo.jp/hall-detail/509',
      googleMap: 'https://maps.app.goo.gl/yHb5AS6AHSVoBScX8'
    },
    contact: {
      name: 'JA兵庫西葬祭センター',
      tel: '0120-079-632'
    },
    reception: `お楽しみ抽選会にご参加いただく場合は、受付が必要となります。
2024年10月14日(月)9:00~11:00 先着200名様限り`,
    fee: '無料',
    imgUrl: ['/images/events/autumnfes.png', '/images/events/autumnfes2.png'],
    finished: true,
    tags: [
      {
        name: 'イベント'
      },
      {
        name: '事前予約不要'
      }
    ],
    details: {
      value: `様々なイベントが目白押しの秋のふれあいフェスタを開催いたします。 
必ず景品がもらえるお楽しみ抽選会も先着200名様限り実施いたします。`,
      schedule: [
        {
          name: '9:00~',
          description: '抽選会・似顔絵・朝市生花 ・キッズコーナー・キッチンカー'
        },
        {
          name: '9:45~9:45',
          description: '宍粟和太鼓アーツクラブ・和太鼓「弾紅」（ダンク）'
        },
        {
          name: '10:30~11:20',
          description: '佐用保育園：ダンス ・ジミーハウス：キッズダンス'
        },
        {
          name: '11:30~12:20',
          description: 'ビンゴゲーム'
        },
        {
          name: '12:30~',
          description: '餅まき'
        }
      ]
    }
  },
  {
    pageId: 1,
    title: 'シティホール豊田 Autumnフェスタ・人形供養祭',
    metaTitle:
      '【参加無料】Autumnフェスタ 人形供養祭やライブなどイベント盛りだくさん',
    eventDate: '2024年10月20日(日)9:00~12:30',
    location: {
      name: 'シティホール豊田',
      companyUrl: 'https://www.city-hall-toyoda.jp/',
      googleMap: 'https://maps.app.goo.gl/mD5jEYmWEDbSBkGS9'
    },
    contact: { name: 'シティホール豊田', tel: '0120-136-344' },
    reception: `人形供養祭へご参加いただく場合は、受付が必要となります。
2024年10月20日(日)9:00~11:00`,
    fee: `イベント参加は、無料となります。
人形供養をする方は、以下の費用となります。
供養料（シティ友の会会員様） 無料
供養料（一般のお客様） 20体まで:2,500円 （税込み） 20体以上:1体につき200円(税込み)`,
    imgUrl: ['/images/events/cityhall1.png', '/images/events/cityhall2.png'],
    finished: true,
    tags: [
      {
        name: 'イベント'
      },
      {
        name: '事前予約不要'
      }
    ],
    details: {
      value: `人形供養祭を開催するにあたり、豪華なイベントを交えたフェスタを開催いたします。
メインイベントとしては、Mr.シャチホコ ライブが開催されます。
また、来場いただいた方全員にもれなくガラポン抽選会にもご参加いただけますので、お気軽にご参加をお待ちしております。`,
      schedule: [
        { name: '9:00~', description: '人形供養祭の受付開始' },
        { name: '9:45~10:15', description: 'フルネス ライブ' },
        { name: '10:30~11:00', description: 'Mr.シャチホコ ライブ' },
        { name: '11:00', description: '人形供養祭の受付終了' },
        { name: '11:15~11:45', description: '人形供養 読経' },
        { name: '12:00~', description: 'お餅投げ＆お菓子投げ' },
        { name: '12:30', description: 'イベント終了' }
      ]
    }
  },
  {
    pageId: 2,
    title: 'JAやすらぎホール津山感謝祭/人形供養祭',
    metaTitle:
      '【参加無料】JAやすらぎホール津山感謝祭 無料人形供養や他イベントあり',
    eventDate: '2024年10月20日(日)9:00~12:00',
    location: {
      name: 'JAやすらぎホール津山',
      companyUrl: 'https://harada-kyodo.jp/hall-detail/380',
      googleMap: 'https://maps.app.goo.gl/EPRvMqrZNSZrmV437'
    },
    contact: {
      name: '津山メモリアルセンター',
      tel: '0120-831-983'
    },
    reception: `人形供養祭へご参加いただく場合は、受付が必要となります。
2024年10月20日(日)9:00~10:20`,
    fee: '無料',
    imgUrl: ['/images/events/haradaEvent.png'],
    finished: true,
    tags: [
      {
        name: 'イベント'
      },
      {
        name: '事前予約不要'
      }
    ],
    details: {
      value: `大切にしていた人形にありがとうの気持ちをこめた人形供養祭や華やかな衣装を身にまとい、
優雅に踊るハワイアンフラなどのメインイベントに加えてメモリアル写真撮影会も開催いたします。`,
      schedule: [
        { name: '9:00~10:20', description: '人形供養祭の受付時間' },
        { name: '10:30~11:00', description: '供養祭' },
        { name: '11:15~11:45', description: 'ハワイアンフラ' },
        { name: '11:45', description: '餅投げ・フィナーレ' }
      ]
    }
  },
  {
    pageId: 3,
    title: 'JA祭典なめがた潮来ホール人形供養祭・餅まき・友引市',
    metaTitle:
      '【参加無料】JA祭典なめがた潮来ホール人形供養祭 豪華景品がゲットできる餅まきなど',
    eventDate: '2024年11月17日(日)9:00~13:00',
    location: {
      name: 'JA祭典なめがた潮来ホール',
      companyUrl: 'https://sougi.bestnet.ne.jp/itako/',
      googleMap: 'https://maps.app.goo.gl/c5XqFaUyPxF77C5s8'
    },
    contact: {
      name: 'JA祭典なめがた潮来ホール',
      tel: '0120-993-794'
    },
    reception: `以下の日時で人形をお預かりいたします。
①2024年11月9日(土)、10日(日)、16日(土)いずれの日も14:00~16:00
②2024年11月17日(日) 9:00~10:00`,
    fee: `イベント参加は無料となります。
人形供養をする方は、以下の費用となります。
供養料（メモリアル会員様） 無料
供養料(一般のお客様)10体まで:500円(税込み)11体以上:1体につき1,000円(税込み)`,
    imgUrl: ['/images/events/2024_11_17.png'],
    tags: [
      {
        name: 'イベント'
      },
      {
        name: '事前予約可'
      },
      {
        name: '当日予約'
      }
    ],
    finished: true,
    details: {
      value: `メインイベントである人形供養祭のほか、豪華景品がゲットできる餅まきや抽選会を実施いたします。
また、模擬店も開催しているのでお気軽にご参加ください。`,
      schedule: [
        { name: '9:00~10:00', description: '人形受付' },
        {
          name: '9:00~無くなり次第終了',
          description: `ピー太郎とじゃんけん！ピーマンを貰おう！
友引市
模擬店
生花販売`
        },
        { name: '9:30~11:00', description: '源囃子連 演奏' },
        { name: '11:00~12:00', description: '供養祭式典' },
        { name: '12:00~13:00', description: '餅まき・抽選会' }
      ]
    }
  },
  {
    pageId: 4,
    title: 'やすらぎホール音楽祭 津軽民謡・津軽三味線他',
    metaTitle: `【参加無料】やすらぎホール音楽祭
津軽民謡歌手かすみさんのライブや津軽三味線など`,
    eventDate: '2024年11月23日(土)9:00~12:30',
    location: {
      name: 'JAやすらぎホール黒石',
      companyUrl: 'https://harada-kyodo.jp/hall-detail/814',
      googleMap: 'https://maps.app.goo.gl/XsiuhsCCCatTZDjx5'
    },
    contact: {
      name: 'JAやすらぎホール黒石',
      tel: '0120-7676-60'
    },
    reception: `事前予約は不要です。`,
    fee: `無料`,
    imgUrl: [
      '/images/events/2024_11_23-1.png',
      '/images/events/2024_11_23-2.png'
    ],
    tags: [
      {
        name: 'イベント'
      },
      {
        name: '事前予約不要'
      }
    ],
    finished: true,
    details: {
      value: `ダンスパフォーマンスや津軽民謡歌手のかすみによる民謡ショー、さらには豪華ゲストのホリ・ハリウリサが登場する音楽祭を実施いたします。
また、地元野菜の直売店や写真撮影会、豪華景品が当たる抽選会も合わせて実施いたします。
さらに先着300名様限定で入場者に粗品をプレゼントもございますので、皆さん一緒に楽しみましょう！`,
      schedule: [
        { name: '9:20~', description: 'S.D.C.C. DANCE STUDIO' },
        {
          name: '9:50~',
          description: `正調黒石ねぷたばやし`
        },
        {
          name: '10:30~',
          description: `かすみの民謡ショー`
        },
        {
          name: '11:30~',
          description: `ホリ・ハリウリサ`
        }
      ]
    }
  },
  {
    pageId: 10,
    title: 'JA祭典那珂ホール感謝祭 輪投げ/終活エンディングノート',
    metaTitle:
      '【参加無料】JA祭典那珂ホール感謝祭 輪投げや門松作りなどイベント盛りだくさん',
    eventDate: '2024年12月15日(日)10:00~15:00',
    location: {
      name: 'JA祭典那珂ホール',
      companyUrl: 'https://sougi.bestnet.ne.jp/naka/',
      googleMap: 'https://maps.app.goo.gl/bgQGdV5qKsiBBTKt6'
    },
    contact: {
      name: 'JA祭典那珂ホール',
      tel: '029-352-0983'
    },
    reception: `事前予約は不要です。
※抽選会にご参加の場合は、当日に受付が必要となります。`,
    fee: `無料`,
    imgUrl: ['/images/events/10.png'],
    tags: [
      {
        name: 'イベント'
      },
      {
        name: '事前予約不要'
      }
    ],
    finished: true,
    details: {
      value: `組合員・地域の皆様へのありがとうの気持ちを込めて感謝祭を開催いたします。
楽しいイベントが盛りだくさん！ご家族みんなでご来場ください。`,
      schedule: [
        {
          name: '10:00～',
          description: `販売コーナー　
「焼き芋・豚汁・お米・野菜チャリティー・模擬店など」
体験コーナー
「輪投げ・門松作り・竹ランタン作りなど」
事前相談会　※終活エンディングノート先着50名様`
        },
        {
          name: '11:00～',
          description: `①たまごパックタイムセール　※数量限定`
        },
        {
          name: '13:00～',
          description: `②たまごパックタイムセール　※数量限定`
        },
        {
          name: '14:00～',
          description: `抽選会 
※参加される方は当日受付が必要となります。
※先着100名様`
        }
      ]
    }
  },
  {
    pageId: 11,
    title: '東水戸ホール Xmas会＆人形供養祭・門松作り',
    metaTitle:
      '【参加無料】Xmas会＆人形供養祭 ハンドベル演奏会などイベント多数',
    eventDate: '2024年12月21日(土)15:00~19:00',
    location: {
      name: '東水戸ホール',
      companyUrl: 'https://sougi.bestnet.ne.jp/higashimito/',
      googleMap: 'https://maps.app.goo.gl/qRLTbW2js4Ze3r4JA'
    },
    contact: {
      name: '東水戸ホール',
      tel: '0120-00-5942'
    },
    reception: `人形供養祭で使用する人形は事前にお預かりしております。
2024年12月14日(土)~2024年12月20日(金) 10:00~16:00`,
    fee: `イベント参加は、無料となります。
人形供養をする方は、以下の費用となります。
供養料（JA水戸メモリアルクラブ会員様）1家族10体まで 無料 ※11体以上は、1,000円 （税込み）
供養料（一般のお客様） 1家族10体まで、1,000円 （税込み） ※11体以上は、2,000円 （税込み）`,
    imgUrl: ['/images/events/11.png'],
    tags: [
      {
        name: 'イベント'
      },
      {
        name: '事前予約不要'
      }
    ],
    finished: true,
    details: {
      value: `様々な来場イベントがあるXmas会＆人形供養祭を開催いたします。
Xmas会らしいハンドベル演奏会やイルミネーションイベントが実施され、最後に抽選会もございます。
ぜひ、ご家族みんなでご来場ください。`,
      schedule: [
        {
          name: '15:00～',
          description: `販売コーナー「お花販売・ギフト販売・模擬店（軽食）」
体験コーナー 「門松作り・竹ランタン作りなど」
事前相談会「いまから帳・事前相談book配布開始」
その他（お子様へ菓子配布）`
        },
        {
          name: '16:00～',
          description: `人形供養祭開式`
        },
        {
          name: '18:00～',
          description: `抽選会`
        }
      ]
    }
  },
  {
    pageId: 12,
    title: '水戸西の杜 2025春のお茶会＆お花見・キッチンカー',
    metaTitle:
      '【参加無料】春のお茶会&お花見 たけのこ掘り体験やキッチンカーなどイベント多数',
    eventDate: '2025年3月30日(日)9:00~13:00',
    location: {
      name: '水戸　西の社墓苑',
      companyUrl: 'https://www.nishinomori.com/',
      googleMap: 'https://maps.app.goo.gl/7rwQue5RzzjP3q2Y8'
    },
    contact: {
      name: '水戸　西の社墓苑',
      tel: '029‐255‐6850'
    },
    reception: `事前予約は不要です。`,
    fee: `イベント参加は、無料となります。
タケノコ掘り体験をする方は、以下の費用となります。
参加費　1家族30分　500円`,
    imgUrl: ['/images/events/12.png'],
    tags: [
      {
        name: 'イベント'
      },
      {
        name: '事前予約不要'
      }
    ],
    finished: true,
    details: {
      value: `様々な来場イベントがある春のお茶会＆お花見を開催いたします。
茶屋の他、キッチンカーの出店やたけのこ掘り体験が実施され、お琴生演奏もございます。
ぜひ、ご家族みんなでご来場ください。`,
      schedule: [
        {
          name: '9:00',
          description: `イベント開始
販売コーナー
「茶屋・キッチンカー・模擬店（軽食）・お花販売・ギフト販売」
体験コーナー
「タケノコ掘り・竹ランタン作りなど」`
        },
        {
          name: '10:00～10:30',
          description: `お琴生演奏`
        },
        {
          name: '10:45～11:15',
          description: `お琴生演奏`
        },
        {
          name: '13:00',
          description: `イベント終了`
        }
      ]
    }
  },
  {
    pageId: 13,
    title: '久喜葬祭社 グラーテス久喜想い出供養祭',
    metaTitle:
      '【完全予約制】人形供養・ぬいぐるみ供養の他遺品・お札・お守りの供養なども',
    eventDate: '2025年4月21日(月)11:00~12:00',
    location: {
      name: 'グラーテス久喜',
      companyUrl: 'https://e-sougi.co.jp/',
      googleMap: 'https://maps.app.goo.gl/XpxqFeNLzYGJLikq5'
    },
    contact: {
      name: 'グラーテス久喜',
      tel: '0480-21-8989（電話受付10:00~16:00）'
    },
    reception: `【4月1日より予約受付開始】※要電話予約
供養祭で使用する供養品は事前にお預かりしております。
2025年4月13日(日)~2024年4月19日(土) 10:00~16:00`,
    fee: `供養料（グラーテス倶楽部会員様）透明な45Lポリ袋２つまで、2,000円
供養料（一般のお客様） 透明な45Lポリ袋２つまで、10,000円 
※品物によってお預かりをお断りする場合がございますので、予めご了承ください。詳しくはお問合せください。
一度お預かりした品物のご返却はできかねますので、よくご確認の上お持ち込みください。`,
    imgUrl: ['/images/events/13-1.jpg', '/images/events/13-2.jpg'],
    tags: [
      {
        name: '完全予約制'
      }
    ],
    details: {
      value: `大切な想い出の品に“ありがとう”を伝えませんか？
想い出の品を真心こめてご供養いたします。
人形、ぬいぐるみ、写真、お札・お守り、賞状・トロフィー、遺品など様々なお品物をお預かりいたします。

【ご供養対象品例】
人形、ぬいぐるみ、写真、アルバム、御札、お守り、数珠、正月飾り、賞状、トロフィー、着物、ランドセル、鞄、眼鏡、腕時計、遺品類、ペットの遺品類、香典袋、弔電、アクセサリー、携帯電話

【ご供養対象外品例】
位牌、仏壇、スプレー缶、汚損品、破損品、家電リサイクル法対象製品、液体類、食品、ナマモノ　他、発火の恐れのある物、毒劇物、ケース類、ガラス板、各種付属品など

【グラーテス俱楽部入会キャンペーン実施中】
ご入会+ご供養料 合計20,000円のところ、想い出供養祭へ参加の方は5,000円でご入会+ご供養が行えます`,
      schedule: []
    }
  }
].reverse() as Array<EventType>;

// {
// pageId: ,
//   title: '',
//   metaTitle:
//     '',
//   eventDate: '',
//   location: {
//     name: '',
//     companyUrl: '',
//     googleMap: ''
//   },
//   contact: {
//     name: '',
//     tel: ''
//   },
//   reception: ``,
//   fee: ``,
//   imgUrl: [''],
//   tags: [
//     {
//       name: ''
//     },
//   ],
//   details: {
//     value: ``,
//     schedule: [
//       { name: '', description: '' },
//       {
//         name: '',
//         description: ``
//       },
//     ]
//   }
// }
